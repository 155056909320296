<template>
  <header>
    <div class="row">
      <div class="col-auto btn-sm-col">
        <button
          v-if="showLeftBtn"
          class="btn btn-nav"
          @click="toEventList()"
        >
          <i class="exu-log-out-02"/>
        </button>
      </div>
      <div class="col">
          <h1>{{ mainTitle }}</h1>
          <p
            v-if="subTitle"
            class="subtitle"
          >
          {{ subTitle }}
        </p>
      </div>
      <div class="col-auto">
        <button
          v-if="showRightBtn"
          class="btn btn-nav"
          @click="$emit('aside-click')"
        >
          <i
            :class="{'exu-menu-01': !isOpen, 'exu-x-close': isOpen}"
          />
        </button>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'MainHeader',
  props: {
    mainTitle: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    // showAsideButton: {
    //   type: Boolean,
    //   default: true,
    // },
    btnBack: {
      type: String,
      default: null,
    },
  },
  computed: {
    showLeftBtn() {
      return this.$store.getters.isVisibleBtnToEventList || this.isOpen;
    },
    showRightBtn() {
      return this.$store.getters.isVisibleBtnMenu;
    },
  },
  mounted() {
  },
  methods: {
    toEventList() {
      this.$router.push({ name: 'EventsListView' });
    },
  },
};
</script>

<style lang="scss">
header {
  position: sticky;
  top: 0;
  z-index: 600;
  grid-area: header;
  display: grid;
  padding: 0 12px;
  // background: linear-gradient(180deg, #356899 0%, #1A334C 100%);
  background: #356899;
  color: #f0f0f0;
  font-family: 'Roboto Condensed';
  // line-height: 1.15rem;

  & > .row {
    align-items: center;
  }

  h1 {
    margin: 0;
    font-size: 16px;
    text-align: center;
    font-weight: 600;

    @media (min-width: 350px) {
      font-size: 20px;
    }
  }
  .subtitle {
    margin: 0;
    font-size: 14px;
    text-align: center;
    font-weight: 400;
  }
  .btn-sm-col {
    width: 40px;
  }
  .btn-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    margin: 0;
    border: none;
    color: #fefefe;
    font-size: 20px;
    background: rgba(255,255,255,.05);
    &:hover {
      background: rgba(255,255,255,.05);
    }
  }
}
</style>
