<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <div class="match-form__wrapper">
    <div class="match-form__time-options">
      <nav-btn-tab
        v-if="optionsDays.length"
        :idNavTab="'nav-tab-days'"
        :tabs="optionsDays"
        :cur-val="selectedDay"
        @change-value="updSelectedDay"
      />
      <nav-btn-tab
        v-if="optionsTime.length"
        :idNavTab="'nav-tab-times'"
        :tabs="optionsTime"
        :cur-val="selectedTime"
        @change-value="updSelectedTime"
      />
    </div>
    <up-card class="match-form">
      <h2>Укажити детали встречи</h2>
      <div class="up-container">
        <div class="up-row">
          <p>Время встречи</p>
        </div>
        <div class="up-row">
          <p class="meet-detail">
            <strong>{{ selectedDate.day }}</strong>
            <strong>{{ selectedDate.time }}</strong>
          </p>
          <p class="meet-duration"><strong>15&nbsp;минут</strong></p>
        </div>
        <div
          v-if="validateMeet.isInvalid"
          class="up-row"
        >
          <p class="note-validation">
            <span class="note note-invalid">{{ validateMeet.message }}</span>
          </p>
        </div>
      </div>
      <textarea placeholder="Напишите сообщение" />
      <div class="up-btn__line base-3-6-3">
        <button class="up-btn up-btn-primary">Отменить</button>
        <button class="up-btn up-btn-accent">Отправить</button>
      </div>
    </up-card>
  </div>
</template>
<script>
// GET
// + mm/user/:id/timeslot
// + mm/meeting/:id
// POST
// + mm/user/:id/timereserve {user_id, timestamp}
// + mm/meeting/create { ...property }
// PUT
// + mm/meeting/:id { ...property }
// DELETE
// mm/meeting/:id { ...property }
import { DateTime } from 'luxon';
import appBaseNav from '@/components/mixins/appBaseNav';
import meeting from '@/components/mixins/api/apiMethodsMeeting';

export default {
  new: 'EditMatchView',
  props: {
    locale: {
      type: String,
    },
    participantID: {
      type: Number,
      default: null,
    },
    invited_user_id: {
      type: Number,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      meetingProp: {
        meet_id: null,
        user_id: null,
        datetime_ts: null,
        message: null,
      },
      timeSlot: [],
      selectedDay: null,
      selectedTime: null,
    };
  },
  mixins: [appBaseNav, meeting],
  watch: {
    locale() {
      this.$store.commit('setPageTitle', this.$t('meeting.header_title_new_meeting'));
    },
  },
  computed: {
    optionsDays() {
      return this.timeSlot.map((itemDay) => {
        const ts = DateTime
          .fromMillis(itemDay[0].time)
          .setZone(this.$store.getters.timeZone)
          .startOf('day');
        return {
          name: ts.setLocale('ru').toFormat('d MMMM'),
          value: ts.toMillis(),
        };
      });
    },
    indOfDay() {
      const ind = this.optionsDays.findIndex((day) => day.value === this.selectedDay);
      return ind !== -1 ? ind : 0;
    },
    optionsTime() {
      const slots = Array.isArray(this.timeSlot[this.indOfDay])
        ? [...this.timeSlot[this.indOfDay]] : [];
      return slots.map((itemTime) => {
        const ts = DateTime
          .fromMillis(itemTime.time)
          .setZone(this.$store.getters.timeZone);
        return {
          name: ts.setLocale('ru').toFormat('H:mm'),
          value: ts.toMillis(),
          disabled: itemTime.disabled,
        };
      });
    },
    selectedDate: {
      get() {
        let day = 'Укажите дату, ';
        let time = 'выберите время';
        if (this.selectedDay) {
          const dt = DateTime.fromMillis(this.selectedDay).setZone(this.$store.getters.timeZone);
          day = dt.setLocale('ru').toFormat('d MMMM, EEEE, ');
        }
        if (this.selectedTime) {
          const dt = DateTime.fromMillis(this.selectedTime).setZone(this.$store.getters.timeZone);
          time = dt.setLocale('ru').toFormat('H:mm,');
        }
        return { day, time };
      },
    },
    validateMeet() {
      const validate = {
        isInvalid: false,
        message: null,
      };
      if (!this.selectedDay || !this.selectedTime) {
        let validateMsg = null;
        if (!this.selectedDay) validateMsg = 'день';
        if (!this.selectedTime) validateMsg = validateMsg === null ? 'время' : `${validateMsg} и время`;

        if (validateMsg) {
          validate.isInvalid = true;
          validate.message = `Выберете ${validateMsg} в верху экрана`;
        }
      } else {
        const slot = this.optionsTime
          .find((itemTime) => itemTime.value === this.selectedTime);
        if (slot.disabled) {
          validate.isInvalid = true;
          validate.message = 'Это время занято';
        }
      }

      return validate;
    },
  },
  mounted() {
    this.$store.commit('setPageTitle', this.$t('meeting.header_title_new_meeting'));

    this.meetingTimeSlot()
      .then((data) => {
        // console.log(data);
        this.timeSlot = data;
      })
      .catch((error) => {
        console.error(error);
      });
  },
  methods: {
    updSelectedDay(ts) {
      this.selectedDay = ts;
      if (this.selectedTime) {
        const { year, month, day } = DateTime.fromMillis(ts).toObject();
        this.selectedTime = DateTime.fromMillis(this.selectedTime)
          .set({ year, month, day }).toMillis();
      }
      console.log(this.indOfDay);
    },
    updSelectedTime(time) {
      this.selectedTime = time;
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/style/variables.scss';

$bg-color-base: $color-base;
$padding-step: 4px;
$border-radius-inside: 8px;
$border-radius-inside-large: 16px;
$border-radius-outside-large: 24px;

@import '@/assets/style/form.scss';

.match-form {
  font-size: 14px;
  color: $color-text;
  &__wrapper {
    display: flex;
    max-width: 760px;
    flex-wrap: wrap;
    padding: 12px;
    margin: 0 auto;
    gap: 12px;

    & > * {
      flex: 1 0 100%;
    }
    & > .upCard {
      border-radius: 16px;
    }
  }
  &__time-options {

  }
  & h2 {
    font-size: 16px;
    color: $color-primary;
    margin-bottom: 0;
  }
  & p {
    margin: 0;
    &.meet-duration {
      width: 100%;
      text-align: right;

      @media (min-width: 350px) {
        & {
          width: auto;
        }
      }
    }
    &.meet-detail {
      display: flex;
      flex-direction: column;
      @media (min-width: 350px) {
        & {
          flex-direction: row;
          flex-wrap: wrap;
        }
      }

      & > strong {
        white-space: nowrap;
      }
    }
  }
  & textarea {
    height: 12em;
    margin: 0 -4px;
    padding: 8px 4px;
    border: 1px solid $color-default;
    border-radius: 8px;
    &::placeholder {
      color: $color-default;
    }
  }
  .note {
    white-space: nowrap;
    font-size: .8rem;

    &-validation {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
    }

    &-invalid {
      color: $color-text-danger;
    }
  }
}
</style>
