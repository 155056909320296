<!-- eslint-disable vue/no-side-effects-in-computed-properties -->
<template>
  <Transition name="progress">
    <progress
      v-if="isVisibleProgress"
      :value="progressVal"
      max="100"
      class="progress"
    />
  </Transition>
  <Transition name="connectionStatus__wrapper">
    <div
      v-if="isVisibleConnectionStatus"
      class="connectionStatus__wrapper">
      <div
        :class="{'success': connectionStatus, 'error': !connectionStatus}"
        class="connectionStatus"
      >
        <span v-if="connectionStatus">{{  $t('system.net_connected') }}</span>
        <span v-else>{{  $t('system.net_unavailable') }}</span>
      </div>
    </div>
  </Transition>
</template>
<script>
export default {
  name: 'progress-bar',
  data() {
    return {
      visibleConnectionStatus: false,
      visibleProgress: false,
      timeOutID: null,
      timeOutConnectionID: null,
    };
  },
  watch: {
    connectionStatus() {
      this.showConnectionStatus();
      this.visibleProgress = false;
    },
    progressVal(newVal) {
      if (newVal === 0) this.visibleProgress = false;
      if (newVal > 0 && newVal < 99 && this.connectionStatus) this.visibleProgress = true;
      if (newVal >= 99) this.hiddenProgress();
    },
  },
  computed: {
    connectionStatus() {
      return this.$store.getters.isConnected;
    },
    progressVal() {
      return this.$store.getters.isProgressVal;
    },
    isVisibleConnectionStatus() {
      return this.visibleConnectionStatus;
    },
    isVisibleProgress() {
      return this.visibleProgress;
    },
  },
  mounted() {
    this.$store.commit('setProgressVal', 0);
  },
  beforeUnmount() {
    clearTimeout(this.timeOutID);
  },
  methods: {
    hiddenProgress() {
      clearTimeout(this.timeOutID);
      this.timeOutID = setTimeout(() => {
        this.visibleProgress = false;
      }, 1000);
      // } else {
      //   this.visibleProgress = true;
      // }
    },
    showConnectionStatus() {
      clearTimeout(this.timeOutConnectionID);
      this.visibleConnectionStatus = true;
      this.timeOutConnectionID = setTimeout(() => {
        this.visibleConnectionStatus = false;
      }, 5000);
    },
    hiddenConnectionStatus() {
      clearTimeout(this.timeOutConnectionID);
      this.visibleConnectionStatus = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.progress {
  display: block;
  box-sizing: border-box;
  // border: solid $b #242b35;
  width: 100%;
  height: 5px;
  opacity: 1;
  border-radius: 1px;
  background: #356899;
  // background: #F2F6FD;
  transition: opacity .1s linear;

  &.progress-enter-from,
  &.progress-leave-to {
    opacity: 0;
  }
  &.progress-enter-to,
  &.progress-leave-from {
    opacity: 1;
  }

  &::-webkit-progress-bar { background: transparent }

  &::-webkit-progress-value {
    transition: width .5s ease-in-out;
    border-radius: 1px;
    box-shadow: inset 0 .05em .05em rgba(#fff, .35);
    background: linear-gradient(
      90deg,
      #D76535 0%,
      #da8662 50%,
      #D76535 100%,
    );
   }
  &::-moz-progress-bar {
    transition: width .5s ease-in-out;
    border-radius: 1px;
    box-shadow: inset 0 .05em .05em rgba(#fff, .35);
    background: linear-gradient(
      90deg,
      #D76535 0%,
      #da8662 50%,
      #D76535 100%,
    );
   }
}
.connectionStatus {
  flex: 1 1 100%;
  display: block;
  box-sizing: border-box;
  margin: 4px;
  padding: 3px 6px;
  text-align: center;
  font-size: .8rem;
  transition: transform .25s ease-in-out;

  &__wrapper {
    display: flex;
    align-items: center;
    height: 36px;
    opacity: 0;
    background-color: #FFFFFF;
    transition: opacity .25s ease-in-out;

    &-enter-from,
    &-leave-to {
      opacity: 0;
    }

    &-enter-to,
    &-leave-from {
      opacity: 1;
    }

    &-enter-from .connectionStatus,
    &-leave-to .connectionStatus {
      transform: translateY(-100%);
    }

    &-enter-to .connectionStatus,
    &-leave-from .connectionStatus {
      transform: translateY(0);
    }
  }

  &.success {
    border: 1px solid #50AA50;
    background: #DDFFDD;
    color: #106610;
  }
  &.error {
    border: 1px solid #FF6060;
    background: #FFE0E0;
    color: #AA1010;
  }

  &.connectionStatus-enter-from,
  &.connectionStatus-leave-to {
    transform: translateY(-100%);
  }
  &.connectionStatus-enter-to,
  &.connectionStatus-leave-from {
    transform: translateY(0);
  }
}
</style>
