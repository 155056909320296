import api from '@/components/mixins/baseApi';

export default {
  mixins: [api],

  computed: {
    deviceHash: {
      get() {
        const DEVICE_HASH = localStorage.getItem('deviceHash') || null;
        return DEVICE_HASH;
      },
      set(prop) {
        const randomString = 'XXXX-XXXX-XXXX-XXXX'.replaceAll('X', () => Math.floor(Math.random() * 10));
        const hash = `${prop}-${randomString}`;
        localStorage.setItem('deviceHash', hash);
      },
    },
  },
  methods: {
    initExhibition(_exhID) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        const config = await this.getConfig(_exhID);
        const inbox = await this.checkInbox();

        if (config) {
          resolve([config, inbox]);
        } else {
          reject();
        }
      });
    },

    getConfig(_exhID) {
      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiPath}/${this.$store.state.axios.lang}/${_exhID}/config.json`,
      })
      // return axios.get(`${this.$store.state.axios.domain}/wapi/v1/ru/${id}/config`)
        // eslint-disable-next-line arrow-body-style
        .then((data) => {
          if (data.results) {
            localStorage.setItem(`exhID-${_exhID}-config`, JSON.stringify(data.results));
          }
          // if (data.response?.status === 404) {
          //   localStorage.removeItem('exhID');
          //   return null;
          // }
          return data?.results;
        })
        .catch((error) => {
          Promise.reject(error);
        });
    },
    applyConfig(config) {
      this.$store.commit('setMainTitle', { lang: 'ru', text: config.locale.name.ru });
      this.$store.commit('setMainTitle', { lang: 'en', text: config.locale.name.en });
      this.expoData.start_ts = config.date_start_ts;
      this.expoData.end_ts = config.date_end_ts;

      this.$store.commit('setTime_UTC', config.event_timezone || null);

      this.$store.commit('setExhTimeStart', config.date_start_ts);
      this.$store.commit('setExhTimeEnd', config.date_end_ts);
      document.querySelector('title').innerText = config.appname;
      document.querySelector("link[rel~='icon']").href = config.favicon['64'];
      this.expoData.launchScreen = config.launch_screen;

      if (config.apps && Array.isArray(config.apps)) {
        this.expoData.apps = [...config.apps];
      } else {
        this.expoData.apps = [];
      }
    },
    checkInbox() {
      if (this.$store.getters.getExhId) {
        return this.baseAxios({
          method: 'get',
          url: `${this.$store.getters.apiBasePath}/inbox/state`,
        })
          .then((data) => data?.results || {})
          .catch((error) => {
            console.error(error);
            return Promise.resolve({ notification: 0, chat: 0 });
          });
      }
      return Promise.resolve({ notification: 0, chat: 0 });
    },
    // TODO переименовать проверку LocalStorage
    checkToken() {
      const token = localStorage.getItem('token');
      const programTs = localStorage.getItem('bannerProgramTs');
      const sponsorTs = localStorage.getItem('bannerPartnerTs');
      const participantTs = localStorage.getItem('bannerParticipantTs');
      const speakerTs = localStorage.getItem('bannerSpeakerTs');
      this.$store.commit('setToken', token || null);
      this.$store.commit('setBannerProgramTs', programTs || null);
      this.$store.commit('setBannerPartnerTs', sponsorTs || null);
      this.$store.commit('setBannerParticipantTs', participantTs || null);
      this.$store.commit('setBannerSpeakerTs', speakerTs || null);
      this.$store.commit('setLang', localStorage.getItem('__exup__locale') || 'ru');
    },
    logMetrika({
      // eslint-disable-next-line camelcase
      url, action, props,
    }) {
      // eslint-disable-next-line camelcase
      const user_id = this.$store.getters.getUserID;
      // const props = props;
      const timestamp = Date.now();
      if (!this.deviceHash) this.deviceHash = timestamp;
      // console.log(props);
      // Потом убрать от сюда
      // if (!localStorage.getItem('sessionHash')) {
      //   localStorage.setItem('sessionHash', `${Math.round(Math
      // .random() * 1000000000)}${timestamp - Math.round(Math.random() * 1000000000)}`);
      // }
      // const sessionHash = localStorage.getItem('sessionHash');
      // console.log('Metrika props::', _props);
      // eslint-disable-next-line object-curly-newline
      // console.log('Metrika log::', JSON.stringify({ user_id, url, action, props }));
      // eslint-disable-next-line object-curly-newline
      return {
        // user_id, url, action, timestamp, sessionHash, props,
        // eslint-disable-next-line no-undef
        user_id, device_hash: this.deviceHash, url, action, timestamp, props,
      };
    },
    sendLogMetrika(_data) {
      return this.baseAxios({
        method: 'post',
        // url: `${this.$store.getters.apiPath}/metrika/log`,
        url: `${this.$store.getters.apiDomain}/metrika/log`,
        data: _data,
      })
        .then((data) => data)
        .catch((error) => {
          console.error(error);
          Promise.resolve();
        });
    },
  },
};
