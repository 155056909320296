import api from '@/components/mixins/baseApi';

export default {
  data() {
    return {
      prgrssDay: 0,
      prgrssHall: 0,
      prgrssProgramm: 0,
    };
  },
  mixins: [api],
  computed: {
    progress: {
      set({ day, hall, program }) {
        this.prgrssDay = day || this.prgrssDay;
        this.prgrssHall = hall || this.prgrssHall;
        this.prgrssProgramm = program || this.prgrssProgramm;
      },
      get() {
        return Math.round((this.prgrssDay + this.prgrssHall + this.prgrssProgramm) / 3);
      },
    },
  },
  methods: {
    getAllDate(abortSignal) {
      this.progress = { day: 1 };
      this.$store.commit('setProgressVal', this.progress);

      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiProgramsPath}/day-list`,
        signal: abortSignal,
        onDownloadProgress: (progressEvent) => {
          this.progress = { day: Math.round(progressEvent.progress * 100) };
          this.$store.commit('setProgressVal', this.progress);
        },
      })
        .then(({ results }) => {
          const exhID = this.$store.getters.getExhId;
          localStorage.setItem(`exh-${exhID}-day-list`, JSON.stringify(results));
          return results;
        })
        .catch((error) => {
          this.$store.commit('setProgressVal', 100);
          return Promise.reject(error);
        });
    },
    getHallList(abortSignal) {
      this.progress = { hall: 1 };
      this.$store.commit('setProgressVal', this.progress);

      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiProgramsPath}/hall-list`,
        signal: abortSignal,
        onDownloadProgress: (progressEvent) => {
          this.progress = { hall: Math.round(progressEvent.progress * 100) };
          this.$store.commit('setProgressVal', this.progress);
        },
      })
        .then(({ results }) => {
          const exhID = this.$store.getters.getExhId;
          localStorage.setItem(`exh-${exhID}-hall-list`, JSON.stringify(results));
          return results;
        })
        .catch((error) => {
          this.$store.commit('setProgressVal', 100);
          return Promise.reject(error);
        });
    },
    getProgramList(page, hall = null, abortSignal = null) {
      this.progress = { program: 1 };
      this.$store.commit('setProgressVal', this.progress);

      if (page) {
        return this.baseAxios({
          method: 'get',
          url: `${this.$store.getters.apiProgramsPath}/${page}${hall ? `/${hall}` : ''}`,
          signal: abortSignal,
          onDownloadProgress: (progressEvent) => {
            this.progress = { program: Math.round(progressEvent.progress * 100) };
            this.$store.commit('setProgressVal', this.progress);
          },
        })
          .then((data) => {
            this.$store.commit('setProgressVal', 100);
            const exhID = this.$store.getters.getExhId;
            if (data) {
              localStorage.setItem(`exh-${exhID}-bp-${page}`, JSON.stringify(data));
            }
            return data;
          })
          .catch((error) => {
            this.$store.commit('setProgressVal', 100);
            return Promise.reject(error);
          });
      }
      this.$store.commit('setProgressVal', 100);
      return Promise.resolve(null);
    },
  },
};
