<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <up-card
    v-if="checkLecture"
  >
    <div class="program__wrapper">
      <p
        class="program-time"
      >
        <span class="">
          {{ timeStartEnd }}
        </span>
        <span class="">
          {{ dateEvent }}
        </span>
      </p>
      <p
        v-if="sectionsTitle"
        @click="titleHandle"
        class="session-title"
      >
        {{ sectionsTitle }}
      </p>
      <p
        v-if="lectureTitle"
        @click="titleHandle"
        class="program-title"
       >
        {{ lectureTitle }}
      </p>
      <p
        v-if="moderators"
        class="program-moderate">
        {{ $t('shared.lecture.moderator') }}:
        <span
          v-for="moderator in moderators"
          :key="moderator.id"
        >
          {{ moderator.name }}
        </span>
      </p>
      <div class="program-speaker">
        <i class="exu-mic"/>
        <router-link
          v-for="speaker in lecture.speakers"
          :key="speaker.id"
          :to="{ path: speakerLink(speaker.id) }"
          @click="clickSpeakerLink(speaker.id)"
          class="speaker-item"
        >
          {{ speaker. name}}
        </router-link>
      </div>
    </div>
  </up-card>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { DateTime } from 'luxon';

export default {
  name: 'ProgramCard',
  props: {
    nowTime: {
      type: Number,
      default: null,
    },
    sectionId: {
      type: [String, Number],
    },
    sectionName: {
      type: String,
    },
    sectionTitle: {
      type: String,
    },
    sectionStart: {
      type: [String, Number],
    },
    sectionModerators: {
      type: Array,
      default: () => ([]),
    },
    lecture: {
      type: null,
      default: () => null,
    },
  },
  computed: {
    isSetTimeZone() {
      return this.$store.getters.isSetTime_UTC();
    },
    tsToUrlDate() {
      return DateTime.fromMillis(this.sectionStart).toFormat('yyyyLLdd');
    },
    checkLecture() {
      return !!this.lecture && this.lecture.start_ts !== null;
    },
    timeStart() {
      // console.log(this.lecture.start_ts);
      // TODO fix global UTC
      // return DateTime.fromMillis(this.lecture.start_ts).setZone('UTC+0');
      return DateTime.fromMillis(this.lecture.start_ts).setZone(this.$store.getters.getTime_UTC);
    },
    timeEnd() {
      return this.timeStart.plus(this.lecture.duration_ts);
    },
    timeStartEnd() {
      return this.timeStart ? `${this.timeStart.toFormat('H:mm')} - ${this.timeEnd.toFormat('H:mm')}` : '';
    },
    dateEvent() {
      return this.timeStart ? this.timeStart.setLocale(this.$store.getters.getLang).toFormat('d MMMM') : '';
    },
    sectionsTitle() {
      const sectionName = this.sectionTitle
        ? `${this.sectionName}${this.sectionName[this.sectionName.length - 1] !== '.' ? '.' : ''}`
        : '';
      const sectionTitle = this.sectionTitle
        ? `${this.sectionTitle}${this.sectionTitle[this.sectionTitle.length - 1] !== '.' ? '.' : ''}`
        : '';
      return `${sectionName} ${sectionTitle}`.trim();
    },
    lectureTitle() {
      return this.lecture.title || null;
    },
    moderators() {
      if (this.lecture?.moderators.length > 0) {
        return this.lecture.moderators;
      }
      if (this.sectionModerators.length > 0) {
        return this.sectionModerators;
      }
      return null;
    },
    cssPastTime() {
      return false;
    },
  },
  methods: {
    speakerLink(id) {
      return `/speaker/profile/${id}`;
    },
    clickSpeakerLink(id) {
      this.$emit('speakerUpdate', id);
    },
    titleHandle() {
      if (this.sectionId && this.lecture.id) {
        this.$router.push({
          name: 'program',
          params: { date: this.tsToUrlDate },
          hash: `#s_${this.sectionId}_l_${this.lecture.id}`,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.program__wrapper {
  display: flex;
  padding: 2px 13px;
  flex-direction: column;
  align-items: stretch;
  gap: 5px;

  p {
    margin: 0;
    font-size: 14px;
    line-height: 130%; /* 18.2px */
    letter-spacing: -0.14px;
  }

  .program-time {
    display: flex;
    justify-content: space-between;
    color: #356899;
    font-style: normal;
    font-weight: 600;
  }
  .session-title {
    color: #356899;
    font-style: normal;
    font-weight: bold;
  }
  .program-title {
    color: #356899;
    font-style: normal;
  }
  .program-moderate {
    color: #95969D;
  }
  .program-speaker {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    column-gap: 15px;
    color: #95969D;

    .speaker-item {
      display: block;
      color: #95969D;
      font-size: 14px;
      text-decoration: none;
      outline: none;
      white-space: nowrap;

      &.router-link-active  {
        color: #356899;
      }
    }
  }

  &.past-program {
    .program-time, .session-title, .program-title, .speaker-item.router-link-active {
      color: #95969D;
    }
  }
}
</style>
