<template>
  <base-list-view
    class="program-list__wrapper"
  >
    <template v-slot:filter-slot>
      <nav-btn-tab
        v-if="optionsDateList.length"
        :idNavTab="'nav-tab-days'"
        :tabs="optionsDateList"
        :cur-val="selectedDate"
        @change-value="updateProgramList"
      />
      <drop-list
        v-if="optionsHall.length"
        :options="optionsHall"
        :selected="selectedHall"
        :default-name="$t('programList.default_hall')"
        @update="handleUpdateSelected"
      />
    </template>
    <div
      v-if="!isLoading"
      class="program-list"
    >
      <up-card
        v-if="!optionsDateList.length && !programList.length"
        class="help-card"
      >
        <div class="help-meesage">{{ $t('programList.no_program') }}</div>
      </up-card>
      <section-card
        v-for="section in programList"
          :id="`s_${section.id}`"
          :key="`s_${section.id}`"
          :section="section"
          :markedId="listMarkedID"
          :now-time="nowTs"
          :hash-to-scroll="isOpenSection.section === `${section.id}` ? urlHash : null"
          :do-scroll-item="isOpenSection.section === `${section.id}` ? isOpenSection : null"
          @mark-favorite="onMarkFavorite"
      />
    </div>
    <pre-loader v-if="isLoading"/>
  </base-list-view>
</template>
<script>
import { DateTime } from 'luxon';
import global from '@/components/mixins/methodsGlobal';
import appBaseNav from '@/components/mixins/appBaseNav';
import programs from '@/components/mixins/methodsPrograms';
import calendar from '@/components/mixins/methodsCalendar';
import banners from '@/components/mixins/methodsBanners';

import baseListView from '@/components/shared/baseListView.vue';

export default {
  name: 'ListProgramView',
  components: { baseListView },
  props: {
    launchDownload: {
      type: Boolean,
    },
    locale: {
      type: String,
    },
    date: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      baseProgramList: [],
      programList: [],
      DateList: [],
      hallList: [],
      isLoading: false,
      selectedDate: null,
      selectedHall: null,
      countPage: null,
      apiController: {
        date: null,
        hall: null,
        program: null,
      },
      nowTs: null,

      urlHash: null,
    };
  },
  mixins: [global, appBaseNav, programs, calendar, banners],
  watch: {
    locale() {
      this.$store.commit('setPageTitle', this.$t('programList.header_title'));
      this.$emit('loading', true);
      this.initProgram();
    },
  },
  computed: {
    optionsHall() {
      if (this.hallList.length > 0) {
        return this.hallList;
      }
      return [];
    },
    optionsDateList: {
      get() {
        return [...this.DateList].map((itemDay) => {
          // eslint-disable-next-line no-unused-vars
          const [trash, year, month, day, ...rest] = itemDay.match(/^(.{4})(.{2})(.{2})$/);
          return {
            name: DateTime.fromObject({ year, month, day }).setLocale(this.$store.getters.getLang).toFormat('d MMMM'),
            value: itemDay,
          };
        });
      },
      set(_dateList) {
        this.DateList = [..._dateList];
      },
    },
    isOpenSection() {
      const elementLectureId = this.urlHash && this.urlHash.match(/l_\d*/g);
      const elementSectionId = this.urlHash && this.urlHash.match(/s_\d*/g);

      return {
        section: elementSectionId ? elementSectionId[0].replace('s_', '') : null,
        lecture: elementLectureId ? elementLectureId[0].replace('l_', '') : null,
      };
    },
    scrollToItem() {
      return this.urlHash || null;
    },
  },
  mounted() {
    this.$store.commit('setPageTitle', this.$t('programList.header_title'));
    this.loadCachedProgram();
    this.loadUserCalendar();
    if (this.programList.length < 1) this.$emit('loading', true);
    this.initProgram();
    if (this.$route.hash) {
      this.urlHash = this.$route.hash.replace('#', '');
    }
  },
  methods: {
    initProgram() {
      this.timeNowTs();
      this.apiController.date = new AbortController();
      this.apiController.hall = new AbortController();
      Promise.all([
        this.getBanner('program', this.$store.getters.getBannerProgramTs),
        this.getAllDate(this.apiController.date.signal),
        this.getHallList(this.apiController.hall.signal),
      ])
        .then(([
          banner,
          allDate,
          allHalls,
        ]) => {
          if (banner && Object.keys(banner).length) {
            this.$emit('showBanner', banner);
            const nowMilis = DateTime.now().toMillis();
            localStorage.setItem('bannerProgramTs', nowMilis);
            this.$store.commit('setBannerProgramTs', nowMilis);
          }
          if (allDate) {
            this.optionsDateList = allDate;
            if (this.date) {
              this.selectedDate = this.date;
            } else {
              this.$router.push({ name: 'program', params: { date: allDate[0] } });
              [this.selectedDate] = allDate;
            }
          }
          if (allHalls) this.setHallList(allHalls);
        })
        .finally(() => {
          this.getPage();
          this.$emit('loading', false);
        })
        .catch((error) => {
          console.error('List program error:: ', error);
        });
    },
    setHallList(hallList_) {
      // TODO забыл почему так
      this.hallList = [...hallList_];
    },
    getPage() {
      if (this.programList.length < 1) this.isLoading = true;
      const fixSelectedDate = this.selectedDate;
      this.apiController.program = new AbortController();
      this.getProgramList(this.selectedDate, this.selectedHall, this.apiController.hall.signal)
        .then((data) => {
          this.$emit('turnOff', {
            stateDownload: false,
            stateCheckInbox: false,
          });
          if (data !== null) {
            if (fixSelectedDate === this.selectedDate) {
              this.baseProgramList = [...data.results];
              this.programList = [...data.results];
            }
            this.countPage = data.num_pages;
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    updateProgramList(newDate) {
      this.selectedDate = newDate;
      this.$router.push({ name: 'program', params: { date: newDate } });
      this.loadCachedProgram();
      this.getPage();
    },
    handleUpdateSelected(selected) {
      this.selectedHall = selected;
      this.programList = selected === null
        ? this.baseProgramList
        : this.baseProgramList.filter((item) => item.place_id === selected);
    },
    loadCachedProgram() {
      const exhID = this.$store.getters.getExhId;
      const jsonDateList = localStorage.getItem(`exh-${exhID}-day-list`);
      const jsonHallList = JSON.parse(localStorage.getItem(`exh-${exhID}-hall-list`));

      if (jsonDateList) {
        const parseDateList = JSON.parse(jsonDateList);
        this.optionsDateList = (Array.isArray(parseDateList) && parseDateList.length > 0)
          ? parseDateList : [];
      }
      if (jsonHallList) this.setHallList(jsonHallList);
      if (!this.selectedDate && jsonDateList && this.optionsDateList.length > 0) {
        this.selectedDate = this.date || this.optionsDateList[0].value;
      }

      const jsonProgram = localStorage.getItem(`exh-${exhID}-bp-${this.selectedDate}`);
      // TODO не подходит для постраничной загрузки ДП
      if (jsonProgram && jsonProgram !== 'null') {
        const program = JSON.parse(jsonProgram);
        this.baseProgramList = [...program.results];
        this.programList = [...program.results];
      } else {
        this.programList = [];
      }
    },
    onMarkFavorite(data) {
      if (this.listMarkedID.includes(data.id)) {
        this.removeFromUserCalendar(data.id);
        this.sendLogMetrika(this.logMetrika({
          url: this.$route.fullPath,
          action: 'remove from calendar',
          props: JSON.stringify(this.userCalendar),
        }));
      } else {
        this.addToUserCalendar(data);
        this.sendLogMetrika(this.logMetrika({
          url: this.$route.fullPath,
          action: 'add to calendar',
          props: JSON.stringify(this.userCalendar),
        }));
      }
    },
    timeNowTs() {
      this.nowTs = DateTime.now().toMillis();
      this.$store.commit('setProgramTimer', this.startTimeoutNow());
    },
    startTimeoutNow() {
      if (this.$store.getters.getProgramTimer !== null) {
        this.endTimeoutNow();
      }
      return setTimeout(this.timeNowTs, 60000);
    },
    endTimeoutNow() {
      if (this.$store.getters.getProgramTimer !== null) {
        clearTimeout(this.$store.getters.getProgramTimer);
        this.$store.commit('setProgramTimer', null);
      }
    },
  },
  beforeUnmount() {
    this.endTimeoutNow();
  },
};
</script>
<style lang="scss">
.program {
  &-list {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    &__wrapper {
      gap: 12px;
      .listView__filter-container {
        padding: 0;
        background: #fefefe;
      }
    }

    .section-card {
      flex: 1 1 100%;
    }
  }
}
</style>
