import api from '@/components/mixins/baseApi';
import { DateTime } from 'luxon';

export default {
  mixins: [api],

  methods: {
    meetingTimeSlot(userID) {
      console.log(userID);

      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiBasePath}/inbox/state`,
      })
        .then((data) => {
          console.log('meetingTimeSlot', data);
          return this.generateTimeSlot();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    meetingTimeReserve() {
      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiBasePath}/inbox/state`,
      })
        .then(() => {

        })
        .catch((error) => {
          console.log(error);
        });
    },
    getMeeting(meetingID) {
      console.log(meetingID);

      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiBasePath}/inbox/state`,
      })
        .then(() => {

        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveMeeting(meetingID) {
      if (meetingID) {
        return this.editMeeting(meetingID);
      }
      return this.newMeeting();
    },
    newMeeting() {
      return this.baseAxios({
        // method: 'post',
        method: 'get',
        url: `${this.$store.getters.apiBasePath}/inbox/state`,
      })
        .then(() => {

        })
        .catch((error) => {
          console.log(error);
        });
    },
    editMeeting(meetingID) {
      console.log(meetingID);

      return this.baseAxios({
        // method: 'post',
        method: 'get',
        url: `${this.$store.getters.apiBasePath}/inbox/state`,
      })
        .then(() => {

        })
        .catch((error) => {
          console.log(error);
        });
    },
    // for develop test
    generateTimeSlot() {
      let time = this.$store.getters.exhTimeStart;
      const timeEnd = this.$store.getters.exhTimeEnd;

      const arrSlots = [[]];
      let arrSlotIndex = 0;
      while (time < timeEnd) {
        const isDisabled = Math.random() > 0.75;
        arrSlots[arrSlotIndex].push({ time, disabled: isDisabled });
        const dt = DateTime.fromMillis(time).setZone(this.$store.getters.timeZone);

        if (dt.plus({ minutes: 15 }).hour < 18) {
          time = dt.plus({ minutes: 15 }).toMillis();
        } else {
          time = dt.plus({ days: 1 }).startOf('day').plus({ hours: 9 }).toMillis();
          if (time < timeEnd) arrSlots.push([]);
          arrSlotIndex += 1;
        }
      }
      console.log('generateTimeSlot()', arrSlots, arrSlotIndex);

      return arrSlots;
    },
  },
};
